<template>
  <v-container class="plans-header pa-0">
    <v-img src="img/header-multiple-upsell.webp" height="143" />
    <h1 class="plans-header__text" v-html="$t('Plans.header_text')"></h1>
  </v-container>
</template>

<script>
export default {
  name: 'PlansHeader',
}
</script>

<style lang="scss" scoped>
.plans-header {
  width: 100%;
  position: relative;

  .plans-header__text {
    position: absolute;
    top: calc(50% - 14px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Rubik';
    color: #4b4b4b;
    font-size: 34px;
    line-height: 40px;
    font-weight: 500;

    @media (max-width: 1264px) {
      width: 100%;
      padding: 0 32px;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
    }
  }
}

::v-deep .plans-header__text span {
  font-weight: 800;
}
</style>
