<template>
  <v-container class="pa-0">
    <plans-header />
    <v-container class="plans-home" style="margin-top: -28px">
      <v-card class="plans-home-content">
        <v-carousel
          data-id="mobile-view"
          class="home-content__carousel"
          height="100%"
          hide-delimiters
          :class="currentLanguage"
        >
          <v-carousel-item v-for="(plan, index) in plans" :key="index">
            <plan-card :plan="plan" />
          </v-carousel-item>
        </v-carousel>
        <v-row
          class="home-content__plans ma-0"
          justify="center"
          :class="currentLanguage"
        >
          <div v-for="plan in plans" :key="plan.id">
            <plan-card :plan="plan" />
          </div>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import PlanCard from '@/modules/plans/components/PlanCard.vue'
import PlansHeader from '@/modules/plans/components/PlansHeader.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PlansHome',

  components: {
    PlansHeader,
    PlanCard,
  },

  mounted() {
    this.setPlans(this.currentLanguage)
  },

  watch: {
    currentLanguage: {
      handler(language) {
        this.setPlans(language)
      },
    },
  },

  computed: {
    ...mapGetters('user', ['currentLanguage']),
    ...mapGetters('plans', ['plans']),
  },

  methods: {
    ...mapActions('plans', ['setPlans']),
  },
}
</script>

<style lang="scss" scoped>
.plans-home {
  padding: 0px 28px !important;

  @include d(m) {
    padding: 0px 12px !important;
  }

  .plans-home-content {
    padding: 40px 16px 30px 16px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 8px;
      border-radius: 4px 4px 0 0;
      background: linear-gradient(
        90deg,
        #39af49 0%,
        #2ede46 36.79%,
        #f2cd12 100%
      );
    }
    .home-content__carousel {
      display: block;

      @media (min-width: 1504px) {
        display: none;
      }
      &.en {
        @media (min-width: 1200px) {
          display: none;
        }
      }
      &.es {
        @media (min-width: 1200px) {
          display: none;
        }
      }
    }
    .home-content__plans {
      gap: 40px;

      @media (max-width: 1503px) {
        display: none;
        &.en,
        &.es {
          display: flex;
        }
      }
      &.en {
        @media (max-width: 1199px) {
          display: none;
        }
      }
      &.es {
        @media (max-width: 1199px) {
          display: none;
        }
      }
    }
  }
}
</style>

<style>
.plans-home-content .v-carousel .v-window__prev,
.plans-home-content .v-carousel .v-window__next {
  top: 44px;
  opacity: 0.4;
}
</style>
