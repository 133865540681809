import { render, staticRenderFns } from "./PlanCard.vue?vue&type=template&id=13f4c292&scoped=true"
import script from "./PlanCard.vue?vue&type=script&lang=js"
export * from "./PlanCard.vue?vue&type=script&lang=js"
import style0 from "./PlanCard.vue?vue&type=style&index=0&id=13f4c292&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f4c292",
  null
  
)

export default component.exports