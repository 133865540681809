<template>
  <div v-if="feature.included" class="plan-card-feature">
    <v-icon size="18" color="success">mdi-check-circle</v-icon>
    <span>{{ feature.text }}</span>
  </div>
  <div v-else class="plan-card-feature">
    <v-icon size="18" color="#E0E0E0">mdi-check-circle</v-icon>
    <span>{{ feature.text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.plan-card-feature {
  display: flex;
  align-items: center;

  span {
    font-family: 'Source Sans Pro';
    font-size: 16px;
    color: #170f49;
    margin-left: 14px;
    line-height: 20px;
  }
}
</style>
